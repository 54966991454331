export default {
  maskPattern: 'num',
  blocks: {
    num: {
      mask: Number,
      min: 0,
      max: 100
    }
  }
}
