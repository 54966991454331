<template>
  <ModalBase
    :name="$options.name"
    overlayClose
    disableBodyScroll
    :bodyPadding="false"
    modalContainerClass="max-w-4xl bg-white"
    :showHeader="false"
    @close="closeLead"
  >
    <template #body="{ closeModal }">
      <div class="overflow-hidden h-full">
        <div v-if="!compHasLead" class="container-padding relative flex justify-center">
          <UtilLoader loadingText="Loading lead" :isLoading="true" size="medium" />
        </div>

        <template v-else>
          <div class="flex items-center justify-between px-5">
            <p class="text-gray-darker text-sm leading-tight flex items-center">
              <UtilPriority :priority="compLead.priority" />
              {{ compLead.leadID }}
            </p>

            <a
              class="text-gray-darker hover:text-black text-xl cursor-pointer py-2 px-4 -mr-2"
              aria-label="Close"
              @click="closeModal"
              textLink
            >
              <FontAwesomeIcon :icon="faTimes" />
            </a>
          </div>

          <div class="flex items-start column-wrapper px-5">
            <div class="flex-auto pr-2 pb-4 mr-4 overflow-y-auto h-full">
              <h3 class="font-medium text-lg mt-1">{{ compLead.title }}</h3>

              <p v-if="compLead.detail" class="mt-4">{{ compLead.detail }}</p>

              <UtilTabs :tabs="compTabs" :value="activeTab" @input="activeTab = $event" class="mt-2 mb-4" />

              <template v-if="activeTab === 'notes'">
                <UtilLoader :isLoading="compIsLeadLoading">
                  <UtilNotes :leadDbId="compLead._id" :notes="compLead.notes" @reload-notes="getLead" />
                </UtilLoader>
              </template>

              <template v-if="activeTab === 'listing'">
                <UtilListingDetails v-model="editApi.model.listing" />

                <UtilDivider class="my-4" />

                <h3 class="font-medium text-lg mt-4">Potential Matches</h3>

                <UtilListingMatchmaker
                  @chosenMember="chooseCounterparty"
                  :value="get(compLead, 'counterparties[0]._id', null)"
                  class="mt-4"
                />

                <UtilDivider class="my-4" />

                <h3 class="font-medium text-lg mt-4">Listing Admin</h3>

                <div class="flex items-center mt-2">
                  <span class="flex-shrink-0 text-sm font-medium leading-loose mr-2">0/5 tasks</span>
                  <UtilProgressBar :progressPercentage="20" class="" />
                </div>

                <FormListingManage
                  v-model="editApi.model.listing"
                  @save="updateLead()"
                  :api="editApi"
                  @add-notes="activeTab = 'notes'"
                  class="mt-4"
                />
              </template>

              <template v-else-if="activeTab === 'haulage'">
                <UtilButton v-if="!compHasHaulage" @click="startHaulageQuote()" color="primary">
                  <FontAwesomeIcon :icon="faPlusCircle" />
                  <span>Get Haulage Quote</span>
                </UtilButton>

                <template v-else>
                  <UtilHaulageDetails :haul="editApi.model.haul.haulage" />

                  <h3 class="font-medium text-lg mt-4">Haulage Admin</h3>

                  <div class="flex items-center mt-2">
                    <span class="flex-shrink-0 text-sm font-medium leading-loose mr-2">0/14 tasks</span>
                    <UtilProgressBar :progressPercentage="20" class="" />
                  </div>

                  <FormHaulageManage
                    @save="updateLead()"
                    :api="editApi"
                    v-model="editApi.model.haul"
                    @add-notes="activeTab = 'notes'"
                    class="mt-4"
                  />
                </template>
              </template>

              <!--
              <template v-else-if="activeTab === 'finance'">
                <h3 class="font-medium text-lg mt-4">Finance Admin</h3>

                <div class="flex items-center mt-2">
                  <span class="flex-shrink-0 text-sm font-medium leading-loose mr-2">0/2 tasks</span>
                  <UtilProgressBar :progressPercentage="20" class="" />
                </div>

                <FormFinanceManage
                  v-model="editApi.model.finance"
                  @save="updateLead()"
                  :api="editApi"
                  @add-notes="activeTab = 'notes'"
                  class="mt-4"
                />
              </template>
              -->

              <UtilApiError :models="[leadApi]" class="mt-2" />
            </div>

            <!-- RIGHT SIDEBAR -->
            <div class="flex-shrink-0 pb-4 overflow-y-auto flex-grow overflow-x-hidden h-full">
              <div class="flex items-center">
                <div class="w-4/12 font-medium">Status</div>

                <div class="flex-grow pl-4">
                  <FormSelect
                    name="status"
                    v-model="editApi.model.status"
                    @change="updateLead()"
                    :items="leadStatusOptions"
                    :emptyFirstOption="false"
                    targetClass="w-full"
                    class="mb-0"
                  />
                </div>
              </div>

              <div class="flex items-center mt-4">
                <div class="w-4/12 font-medium">Value</div>

                <div class="flex-grow pl-4">
                  <UtilCurrency :value="compLead.value" class="font-mono text-medium leading-snug" />
                </div>
              </div>

              <div class="flex items-center mt-4">
                <div class="w-4/12 font-medium">Priority</div>

                <div class="flex-grow pl-4">
                  <FormSelect
                    name="priority"
                    v-model="editApi.model.priority"
                    @change="updateLead()"
                    :items="[
                      {
                        label: 'Low',
                        value: 'LOW'
                      },
                      {
                        label: 'Medium',
                        value: 'MEDIUM'
                      },
                      {
                        label: 'High',
                        value: 'HIGH'
                      }
                    ]"
                    :bordered="false"
                    :emptyFirstOption="false"
                    class="mb-0"
                  />
                </div>
              </div>

              <div class="flex mt-4">
                <div class="w-4/12 font-medium">Tasks</div>

                <div class="flex-grow pl-4">
                  <div class="flex items-center">
                    <span class="flex-shrink-0 text-sm font-medium leading-loose mr-2">20%</span>
                    <UtilProgressBar :progressPercentage="20" class="" />
                  </div>
                </div>
              </div>

              <div class="flex items-center mt-4">
                <div class="w-4/12 flex-shrink-0 font-medium">Assignee</div>

                <div class="flex-shrink-0 pl-4">
                  <UtilAssignee
                    :value="compLead.assignee ? compLead.assignee._id : ''"
                    @change="changeAssignee"
                    :showBorder="false"
                    :limit="1"
                    openDirection="below"
                  />
                </div>
              </div>

              <div class="flex mt-4">
                <div class="w-4/12 font-medium">Region</div>

                <div class="flex-grow pl-4">{{ get(compLead, 'region', 'Unknown Region') }}</div>
              </div>

              <div class="mt-4">
                <div class="font-medium">Customer</div>

                <div class="mt-2">
                  <template v-if="compCustomer._id">
                    <a
                      :href="`${baseUrls.adminPortal}/user.php?ref=${compCustomer.profile.userRef}`"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="underline text-link block"
                    >
                      {{ `${compCustomer.profile.firstName} ${compCustomer.profile.lastName}` }}
                    </a>

                    <p class="font-mono mt-1">{{ compCustomer.contact }}</p>

                    <p class="text-sm">{{ compCustomer.email }}</p>
                  </template>

                  <template v-else>Unknown Customer</template>
                </div>
              </div>

              <div class="mt-4">
                <div class="font-medium">Other Party</div>

                <div class="mt-2">
                  <template v-if="compLead">
                    <template v-if="compCounterparty._id && !isEditingCounterparty">
                      <a
                        :href="`${baseUrls.adminPortal}/user.php?ref=${compCounterparty.profile.userRef}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="underline text-link block"
                      >
                        {{ `${compCounterparty.profile.firstName} ${compCounterparty.profile.lastName}` }}
                      </a>

                      <p class="font-mono mt-1">{{ compCounterparty.contact }}</p>

                      <p class="text-sm">{{ compCounterparty.email }}</p>

                      <UtilButton @click="isEditingCounterparty = true" textLink class="underline">
                        Change
                      </UtilButton>
                    </template>

                    <FormCustomer
                      v-else-if="
                        isEditingCounterparty ||
                        (editApi.model.counterparties && editApi.model.counterparties.length > 0)
                      "
                      v-model="editApi.model.counterparties[0]"
                      @save="
                        () => {
                          isEditingCounterparty = false
                          updateLead()
                        }
                      "
                      :isLoading="editApi.isLoading"
                      submitButtonLabel="Save Other Party"
                    />
                  </template>
                </div>
              </div>

              <div class="mt-4 items-center">
                <div class="font-medium flex justify-between items-center">
                  Linked Lead

                  <UtilButton
                    @click="getCustomerLeads()"
                    :isLoading="customerLeadsApi.isLoading"
                    textLink
                    class="text-sm ml-2"
                  >
                    <FontAwesomeIcon :icon="faSyncAlt" />
                    <span>Update Leads</span>
                  </UtilButton>
                </div>

                <div class="flex items-center">
                  <MultiSelect
                    v-model="editApi.model.linkedLeadID"
                    :options="compCustomerLeads"
                    placeholder="Customer Leads"
                    :showLabels="false"
                    :limit="1"
                    :multiple="false"
                    openDirection="above"
                    class="no-border"
                  >
                    <template slot="option" slot-scope="{ option }">
                      {{ option.label }}
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>
                  </MultiSelect>
                </div>
              </div>

              <UtilDivider class="my-4" />

              <div class="text-gray text-sm">
                <p>Created {{ $date.relative(compLead.createdAt) }} by SYSTEM</p>
                <p class="mt-2">Updated {{ $date.relative(compLead.updatedAt) }} by Derek Lewis</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { faPlusCircle, faTimes, faSyncAlt } from '@fortawesome/pro-regular-svg-icons'
import leadStatusOptions from '@/lib/options/lead-status'
import { get } from 'lodash'
import Api from '@/lib/api'
import { confetti } from 'dom-confetti'
import ModalBase from '@/components/modals/ModalBase'
import UtilButton from '@/components/utils/UtilButton'
import FormSelect from '@/components/forms/elements/FormSelect.vue'
import UtilDivider from '@/components/utils/UtilDivider.vue'
import UtilNotes from '@/components/utils/UtilNotes.vue'
import UtilTabs from '@/components/utils/UtilTabs'
import UtilApiError from '@/components/utils/UtilApiError.vue'
import UtilLoader from '@/components/utils/UtilLoader.vue'
import UtilPriority from '@/components/utils/UtilPriority.vue'
import UtilCurrency from '@/components/utils/UtilCurrency.vue'
import UtilProgressBar from '@/components/utils/UtilProgressBar.vue'
import UtilHaulageDetails from '@/components/utils/UtilHaulageDetails.vue'
import UtilListingDetails from '@/components/utils/UtilListingDetails.vue'
import UtilAssignee from '@/components/utils/UtilAssignee.vue'
import UtilListingMatchmaker from '@/components/utils/UtilListingMatchmaker.vue'
import FormHaulageManage from '@/components/forms/FormHaulageManage.vue'
import FormCustomer from '@/components/forms/FormCustomer.vue'
import FormListingManage from '@/components/forms/FormListingManage.vue'

export default {
  name: 'ModalLead',
  components: {
    ModalBase,
    UtilButton,
    FormSelect,
    UtilDivider,
    UtilNotes,
    UtilTabs,
    UtilPriority,
    UtilApiError,
    UtilLoader,
    FormHaulageManage,
    UtilCurrency,
    UtilProgressBar,
    UtilHaulageDetails,
    UtilListingDetails,
    FormListingManage,
    UtilAssignee,
    UtilListingMatchmaker,
    FormCustomer
  },
  props: {
    modalData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      leadApi: new Api(),
      editApi: new Api(),
      customerLeadsApi: new Api(),
      leadData: {},
      leadID: null,
      isEditingAssignee: false,
      isEditingCounterparty: false,
      activeTab: 'notes',
      leadStatusOptions,
      get,
      faPlusCircle,
      faTimes,
      faSyncAlt
    }
  },
  created() {
    this.setup()
  },
  computed: {
    compLead() {
      return this.leadApi.data
    },

    compHasLead() {
      return get(this.compLead, 'title', false)
    },

    compCustomer() {
      return get(this.compLead, 'customers[0]', { profile: {} })
    },

    compCounterparty() {
      return get(this.compLead, 'counterparties[0]', { profile: {} })
    },

    compIsLeadLoading() {
      return this.leadApi.isLoading && typeof this.leadApi.data.notes === 'undefined'
    },

    compTabs() {
      let tabs = [
        {
          label: 'Notes',
          value: 'notes',
          count: get(this.compLead, 'notes', []).length,
          active: true
        },
        {
          label: 'Matchmaker',
          value: 'listing',
          active: !this.compHasHaulage
        },
        {
          label: 'Haulage',
          value: 'haulage',
          active: true
        }
      ]

      return tabs.filter(tab => tab.active)
    },

    compHasHaulage() {
      return get(this.editApi, 'model.haul.haulage', false)
    },

    compCustomerLeads() {
      return get(this.customerLeadsApi, 'data.leads', [])
    }
  },
  methods: {
    async setup() {
      this.leadID = this.modalData.leadID

      // Prefill our lead with the data we have from the board to show something faster
      this.leadApi.data = { ...this.modalData }

      this.editApi.model = { ...this.modalData }

      this.$router.replace({ query: { ...this.$route.query, lead: this.leadID } }).catch(() => {})

      await this.getLead()

      // Auto switch to tab

      // If notes then we need people to read these before moving on to avoid missing anything important
      if (get(this.compLead, 'notes', []).length > 0) {
        this.activeTab = 'notes'
        return
      }

      if (get(this.compLead, 'haul.haulage._id')) {
        this.activeTab = 'haulage'
        return
      }

      if (get(this.compLead, 'listing.listingData.listingRef')) {
        this.activeTab = 'listing'
        return
      }
    },

    async getLead() {
      try {
        await this.leadApi.get(`/v2/crm/leads/${this.leadID}`)

        this.$set(this.editApi, 'model', { ...this.leadApi.data })

        if (this.editApi.model.counterparties.length === 0) {
          this.$set(this.editApi.model, 'counterparties', [
            {
              name: '',
              contact: '',
              email: ''
            }
          ])
        }

        this.getCustomerLeads()
      } catch (error) {
        console.log(error)

        this.$notify.floatError('Error loading lead, please reload and try again')
      }
    },

    async getCustomerLeads() {
      if (!this.compCustomer._id) {
        return false
      }

      await this.customerLeadsApi.get('/v2/crm/leads', {
        fields: [`customer._id|${this.compCustomer._id}`],
        grouped: false
      })
    },

    closeLead() {
      let query = Object.assign({}, this.$route.query)
      delete query.lead

      this.$router.replace({ query }).catch(() => {})
    },

    async updateLead() {
      console.log('Updating lead')

      if (this.editApi.model.status === 'WON' && this.compLead.status !== 'WON') {
        confetti(document.querySelector('#celebration'))
      }

      try {
        await this.editApi.put(`/v2/crm/leads/${this.leadID}`)
      } catch (error) {
        console.log(error)

        this.$notify.floatError('Error updating lead, please reload and try again')
      }
    },

    async changeAssignee(assignee) {
      console.log('Change assignee', assignee)

      try {
        await this.editApi.put(`/v2/crm/leads/${this.leadID}`, { assignee })
      } catch (error) {
        console.log(error)

        this.$notify.floatError('Error updating assignee, please reload and try again')
      }

      this.getLead()
    },

    chooseCounterparty(counterparty) {
      this.editApi.model.counterparties[0] = counterparty
      this.isEditingCounterparty = false

      this.updateLead()
      this.getLead()
    },

    startHaulageQuote() {
      // Get single use token from API for this customer
      // Open new tab to haulage page
    }
  }
}
</script>

<style lang="scss" scoped>
.column-wrapper {
  height: calc(100% - 50px);
}
</style>
