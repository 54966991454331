<template>
  <div class="relative" :class="{ 'z-3': isOpen }">
    <FormLabel v-if="label">{{ label }}</FormLabel>

    <div class="relative flex items-center">
      <FontAwesomeIcon :icon="faCalendarAlt" class="absolute position-center-y z-2 left-15" />

      <Datepicker
        v-model="compValue"
        :disabledDates="disabledDates"
        :highlighted="{
          dates: [new Date()]
        }"
        @opened="
          () => {
            isOpen = true
          }
        "
        @closed="
          () => {
            isOpen = false
          }
        "
        class="form-input date-picker relative pl-12 leading-relaxed w-full"
      />
    </div>
  </div>
</template>

<script>
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import Datepicker from '@sum.cumo/vue-datepicker'
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css'
import FormLabel from '@/components/forms/elements/FormLabel.vue'

export default {
  name: 'FormDatePicker',
  components: {
    FormLabel,
    Datepicker
  },
  props: {
    value: Date,
    label: String,
    disabledDates: Object
  },
  data() {
    return {
      isOpen: false,
      faCalendarAlt
    }
  },
  computed: {
    compValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
