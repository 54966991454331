<template>
  <Form @submit="$emit('save')" ref="form" v-slot="{ formErrors, formSubmissionAttempted }">
    <div class="flex items-start justify-between">
      <FormSelect
        name="haulier"
        v-model="value.haulier.chosen"
        label="Chosen Haulier"
        placeholder="No Haulier Assigned"
        :items="$store.getters['app/hauliers']"
        class="flex-grow pr-4"
        targetClass="w-full"
      />

      <FormDatePicker
        label="Haulier Proposed Date"
        v-model="value.haulier.proposed"
        :disabledDates="{ to: $date().subtract(1, 'day').toDate() }"
      />
    </div>

    <div class="flex">
      <FormTextInput
        name="cost"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="Cost"
        v-model="value.cost"
        :iconPrefix="faPoundSign"
        targetClass="price-field"
        class="mr-4"
      />

      <FormTextInput
        name="margin"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="Calculated Margin"
        v-model="value.margin"
        :iconPrefix="faPoundSign"
        targetClass="price-field"
      />
    </div>

    <div class="rounded border border-gray py-1 px-2 mt-4">
      <div class="flex justify-between mb-2">
        <FormDatePicker
          label="Buyer Contacted"
          v-model="value.buyer.contactedAt"
          :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
          class="w-6/12 pr-2"
        />

        <FormDatePicker
          label="Buyer Responded"
          v-model="value.buyer.respondedAt"
          :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
          class="w-6/12 pl-2"
        />
      </div>

      <div class="flex justify-between">
        <FormRadio
          name="buyerAccepted"
          v-model="value.buyer.accepted"
          label="Buyer Accepted Date"
          :items="[
            {
              text: 'Yes',
              value: true
            },
            {
              text: 'No',
              value: false
            }
          ]"
          horizontal
        />

        <FormDatePicker
          label="Buyer Detail Email Sent"
          v-model="value.buyer.detailAt"
          :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
          class="w-6/12 pl-2"
        />
      </div>
    </div>

    <div class="rounded border border-gray py-1 px-2 mt-4">
      <div class="flex justify-between mb-2">
        <FormDatePicker
          label="Seller Contacted"
          v-model="value.seller.contactedAt"
          :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
          class="w-6/12 pr-2"
        />

        <FormDatePicker
          label="Seller Responded"
          v-model="value.seller.respondedAt"
          :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
          class="w-6/12 pl-2"
        />
      </div>

      <div class="flex justify-between">
        <FormRadio
          name="sellerAccepted"
          v-model="value.seller.accepted"
          label="Seller Accepted Date"
          :items="[
            {
              text: 'Yes',
              value: true
            },
            {
              text: 'No',
              value: false
            }
          ]"
          horizontal
        />

        <FormDatePicker
          label="Seller Detail Email Sent"
          v-model="value.seller.detailAt"
          :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
          class="w-6/12 pl-2"
        />
      </div>
    </div>

    <div class="flex justify-between mt-4">
      <FormDatePicker
        label="Detail Email Sent To Haulier"
        v-model="value.haulier.invoiceAt"
        :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
        class="w-6/12 pr-2"
      />

      <FormDatePicker
        label="Invoice Sent To Buyer"
        v-model="value.buyer.invoiceAt"
        :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
        class="w-6/12 pl-2"
      />
    </div>

    <UtilDivider class="my-6" />

    <FormErrorReport class="mt-4" :formSubmissionAttempted="formSubmissionAttempted" :formErrors="formErrors" />

    <UtilApiError :models="[api]" class="mt-4" />

    <div class="flex items-center mt-4">
      <UtilButton type="submit" color="primary" :isLoading="api.isLoading" class="mr-4">Update Haulage</UtilButton>

      <UtilTextLink @click="$emit('add-notes')">Add Notes</UtilTextLink>
    </div>
  </Form>
</template>

<script>
import { faSearch, faPlusCircle, faPoundSign } from '@fortawesome/pro-regular-svg-icons'
import currencyMask from '@/lib/masks/currency'
import UtilButton from '@/components/utils/UtilButton'
import Form from '@/components/forms/elements/Form'
import FormErrorReport from '@/components/forms/elements/FormErrorReport'
import FormTextInput from '@/components/forms/elements/FormTextInput'
import FormSelect from '@/components/forms/elements/FormSelect.vue'
import FormDatePicker from '@/components/forms/elements/FormDatePicker.vue'
import FormRadio from '@/components/forms/elements/FormRadio.vue'
import UtilTextLink from '@/components/utils/UtilTextLink.vue'
import UtilDivider from '@/components/utils/UtilDivider.vue'
import UtilApiError from '@/components/utils/UtilApiError.vue'

export default {
  name: 'FormHaulageManage',
  components: {
    UtilButton,
    Form,
    FormErrorReport,
    FormTextInput,
    FormSelect,
    FormDatePicker,
    FormRadio,
    UtilTextLink,
    UtilDivider,
    UtilApiError
  },
  props: {
    value: Object,
    api: Object
  },
  data() {
    return {
      currencyMask,
      faPoundSign,
      faSearch,
      faPlusCircle
    }
  }
}
</script>
