var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UtilLoader',{attrs:{"isLoading":_vm.matchApi.loading}},[_c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"245px"}},_vm._l((_vm.compMatches),function(match,index){return _c('div',{key:index,staticClass:"flex justify-between py-1 w-full hover:bg-yellow-100 even:bg-gray-100"},[_c('div',[_c('a',{staticClass:"underline",attrs:{"href":((_vm.baseUrls.adminPortal) + "/user.php?ref=" + (match.userRef)),"target":"_blank"}},[_vm._v("\n            "+_vm._s(match.name)+"\n          ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(match.contact))])]),_vm._v(" "),_c('span',[_vm._v(_vm._s(match.region))]),_vm._v(" "),_c('FormSelect',{staticClass:"ml-4 mb-0",attrs:{"name":"status","value":match.status,"items":[
            {
              label: 'New',
              value: null
            },
            {
              label: 'Contacted',
              value: 'contacted'
            },
            {
              label: 'Matched',
              value: 'matched'
            }
          ],"emptyFirstOption":false,"small":""}}),_vm._v(" "),_c('UtilButton',{staticClass:"ml-2 mr-2 self-start",attrs:{"color":"secondary","small":""}},[_vm._v("Assign")])],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }