<template>
  <div>
    <transition-group name="fade">
      <div v-for="note in notes" :key="note._id" class="mb-4">
        <div class="flex items-center">
          <UtilAdminUser medium :adminData="note.createdBy" />

          <div>
            <span class="block leading-tight">{{ note.createdBy.profile.name || 'Unknown User' }}</span>

            <span class="text-gray leading-tight text-sm block">{{ $date.relative(note.createdAt) }}</span>
          </div>
        </div>

        <p v-if="editNoteApi.model._id !== note._id" class="mt-2">
          {{ note.note }}
        </p>

        <FormTextInput
          v-else
          textArea
          :rows="1"
          ref="editMessage"
          name="editMessage"
          v-model="editNoteApi.model.note"
          autoGrow
          @keydown.native.enter.exact.prevent="saveEditNote"
          class="flex-grow mb-0 mt-2"
          targetClass="w-full"
        />

        <div
          v-if="$date().diff(note.createdAt, 'hour') < 24 && note.createdBy._id === app.user.id"
          class="flex items-center mt-1 text-sm"
        >
          <UtilButton
            @click="toggleEdit(note)"
            :isLoading="editNoteApi.model._id === note._id && (editNoteApi.isLoading || deleteNoteApi.isLoading)"
            textLink
            class="mr-4"
          >
            {{ editNoteApi.model._id === note._id ? 'Cancel' : 'Edit' }}
          </UtilButton>

          <UtilButton
            @click="deleteNote(note)"
            :isLoading="editingNoteId === note._id && (editNoteApi.isLoading || deleteNoteApi.isLoading)"
            textLink
          >
            Delete
          </UtilButton>
        </div>
      </div>
    </transition-group>

    <!-- <div class="opacity-100">
      <div class="flex items-center">
        <UtilAvatar isBot medium class="mr-2" />

        <div>
          <span class="block leading-tight">System created lead from accepted Haulage quote</span>

          <span class="text-gray leading-tight text-sm block">{{ $date().format('llll') }}</span>
        </div>
      </div>
    </div> -->

    <div class="flex mt-8">
      <UtilMyAvatar class="h-12 mr-2" />

      <FormTextInput
        textArea
        :rows="1"
        ref="message"
        name="message"
        v-model="addNoteApi.model.note"
        placeholder="Add a note"
        autoGrow
        @resizeTextArea="$emit('resize-text-area')"
        @keydown.native.enter.exact.prevent="addNote"
        :disabled="addNoteApi.isLoading"
        class="flex-grow mb-0"
        targetClass="w-full"
      />
    </div>

    <UtilApiError :models="[addNoteApi, editNoteApi]" class="mt-2" />
  </div>
</template>

<script>
import { get } from 'lodash'
import Api from '@/lib/api'
import NoteModel from '@/lib/models/note'
import getInitialsFromName from '@/lib/name-initials'
import UtilMyAvatar from '@/components/utils/UtilMyAvatar.vue'
import FormTextInput from '@/components/forms/elements/FormTextInput.vue'
import UtilButton from '@/components/utils/UtilButton.vue'
import UtilApiError from '@/components/utils/UtilApiError.vue'
import UtilAdminUser from '@/components/utils/UtilAdminUser.vue'

export default {
  name: 'UtilNotes',
  components: {
    UtilMyAvatar,
    FormTextInput,
    UtilButton,
    UtilApiError,
    UtilAdminUser
  },
  props: {
    leadDbId: String,
    notes: Array,
    isLoading: Boolean
  },
  data() {
    return {
      addNoteApi: new Api(new NoteModel({ lead: this.leadDbId })),
      editNoteApi: new Api(),
      deleteNoteApi: new Api(),
      editingNoteId: null,
      newNote: '',
      getInitialsFromName,
      get
    }
  },
  computed: {
    compMessage: {
      get() {
        return this.value
      },
      set(message) {
        this.$emit('input', message)
      }
    }
  },
  methods: {
    toggleEdit(note) {
      if (this.editNoteApi.model._id === note._id) {
        this.editNoteApi.model = {}

        return
      }

      this.editNoteApi.model = { ...note }
    },

    async addNote() {
      console.log('Adding note')

      this.addNoteApi.model.createdBy = this.app.user.id

      await this.addNoteApi.post('/v2/crm/notes')

      this.addNoteApi.model.note = ''

      this.$emit('reload-notes')
    },

    async saveEditNote() {
      console.log('Saving edited note')

      await this.editNoteApi.put(`/v2/crm/notes/${this.editNoteApi.model._id}`)

      this.editNoteApi.model = {}

      this.$emit('reload-notes')
    },

    async deleteNote(note) {
      console.log('Deleting note', note)

      this.editingNoteId = note._id

      await this.deleteNoteApi.put(`/v2/crm/notes/${note._id}`, {
        'flags.hidden': true
      })

      this.editingNoteId = null

      this.$emit('reload-notes')
    }
  }
}
</script>
