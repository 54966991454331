const currencyMask = (min = 0, max = 2000000) => {
  return {
    maskPattern: 'num',
    blocks: {
      num: {
        mask: Number,
        padFractionalZeros: true,
        thousandsSeparator: ',',
        radix: '.',
        mapToRadix: [','],
        min,
        max
      }
    }
  }
}

export default currencyMask
