<template>
  <Form @submit="$emit('save')" ref="form" v-slot="{ formErrors, formSubmissionAttempted }">
    <div class="flex justify-between mb-4">
      <FormDatePicker
        label="Buyer Contacted"
        v-model="value.buyer.contactedAt"
        :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
        class="w-6/12 pr-2"
      />

      <FormDatePicker
        label="Deal Agreed"
        v-model="value.buyer.dateTradeAgreed"
        :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
        class="w-6/12 pl-2"
      />
    </div>

    <div class="flex">
      <FormTextInput
        name="pricePerHead"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="Price Per Head"
        v-model="value.prices.pricePerHead"
        :iconPrefix="faPoundSign"
        class="w-6/12 pr-2"
      />

      <FormTextInput
        name="quantity"
        inputmode="numeric"
        autocomplete="off"
        :mask="quantityMask"
        label="Final Quantity"
        v-model="value.finalQuantity"
        class="w-6/12 pl-2"
        targetClass="short-field"
      />
    </div>

    <div class="flex">
      <FormTextInput
        name="sellerComissionPerHead"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="Seller Commission Per Head"
        v-model="value.prices.sellerComissionPerHead"
        :iconPrefix="faPoundSign"
        class="w-6/12 pr-2"
        targetClass="w-full"
      />

      <FormTextInput
        name="buyerComissionPerHead"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="Buyer Commission Per Head"
        v-model="value.prices.buyerComissionPerHead"
        :iconPrefix="faPoundSign"
        class="w-6/12 pl-2"
        targetClass="w-full"
      />
    </div>

    <div class="flex">
      <FormDatePicker
        label="Haulage Date"
        v-model="value.haulageDate"
        :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
        class="w-6/12 pr-2"
      />

      <FormTextInput
        name="haulagePrice"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="Haulage Cost"
        v-model="value.prices.haulageCost"
        :iconPrefix="faPoundSign"
        class="w-6/12 pl-2"
        targetClass="w-full"
      />
    </div>

    <div class="flex">
      <FormDatePicker
        label="VetCheck Date"
        v-model="value.vetcheckDate"
        :disabledDates="{ to: $date().subtract(1, 'week').toDate() }"
        class="w-6/12 pr-2"
      />

      <FormTextInput
        name="VetCheckPrice"
        inputmode="numeric"
        autocomplete="off"
        :mask="currencyMask()"
        label="VetCheck Cost"
        v-model="value.prices.vetcheckCost"
        :iconPrefix="faPoundSign"
        class="w-6/12 pl-2"
        targetClass="w-full"
      />
    </div>

    <div class="flex items-center mt-4">
      <FormCheckbox
        label="Farmpay"
        name="farmpay"
        v-model="value.farmpay"
        class="mr-4"
        :trueValue="true"
        :falseValue="false"
      />

      <FormCheckbox
        label="Finance"
        name="finance"
        v-model="value.finance"
        class="mr-4"
        :trueValue="true"
        :falseValue="false"
      />
    </div>

    <UtilDivider class="my-4" />

    <h3 class="font-medium text-lg mb-2">Invoicing</h3>

    <div class="flex">
      <div class="w-6/12 pr-2">Total invoice value to Buyer:</div>

      <!-- Calculation: (Price per head * Quanity) + (BUYER Commission * Quantity) + Haulage Cost + VetCheck Cost -->
      <UtilCurrency :value="120.85" class="font-bold ml-2" />
    </div>

    <div class="flex mt-2">
      <div class="w-6/12 pr-2">Total payout to Seller:</div>

      <!-- Calculation: (Price per head * Quanity) - (SELLER Commission * Quantity) -->
      <UtilCurrency :value="76.48" class="font-bold ml-2" />
    </div>

    <div class="flex mt-4">
      <FormTextInput
        name="sellerBankAccountNumber"
        label="Seller Bank Account Number"
        v-model="value.seller.bankAccountNumber"
        class="w-6/12 pr-2"
      />

      <FormTextInput
        name="sellerBankAccountSortCode"
        label="Seller Account Sort Code"
        v-model="value.seller.bankAccountSortCode"
        class="w-6/12 pl-2"
      />
    </div>

    <FormTextInput name="sellerBankName" label="Seller Bank Name" v-model="value.seller.bankName" class="w-full" />

    <UtilDivider class="my-6" />

    <FormErrorReport class="mt-4" :formSubmissionAttempted="formSubmissionAttempted" :formErrors="formErrors" />

    <UtilApiError :models="[api]" class="mt-4" />

    <div class="flex items-center mt-4">
      <UtilButton type="submit" color="primary" :isLoading="api.isLoading" class="mr-4">
        Update Matchmaking
      </UtilButton>

      <UtilTextLink @click="$emit('add-notes')">Add Notes</UtilTextLink>
    </div>
  </Form>
</template>

<script>
import { faSearch, faPlusCircle, faPoundSign, faPercent } from '@fortawesome/pro-regular-svg-icons'
import currencyMask from '@/lib/masks/currency'
import quantityMask from '@/lib/masks/quantity'
import percentageMask from '@/lib/masks/percentage'
import UtilButton from '@/components/utils/UtilButton'
import Form from '@/components/forms/elements/Form'
import FormErrorReport from '@/components/forms/elements/FormErrorReport'
import FormTextInput from '@/components/forms/elements/FormTextInput'
import FormDatePicker from '@/components/forms/elements/FormDatePicker.vue'
import UtilTextLink from '@/components/utils/UtilTextLink.vue'
import UtilDivider from '@/components/utils/UtilDivider.vue'
import UtilApiError from '@/components/utils/UtilApiError.vue'
import FormCheckbox from '@/components/forms/elements/FormCheckbox.vue'
import UtilCurrency from '@/components/utils/UtilCurrency.vue'

export default {
  name: 'FormListingManage',
  components: {
    UtilButton,
    Form,
    FormErrorReport,
    FormTextInput,
    FormDatePicker,
    UtilTextLink,
    UtilDivider,
    UtilApiError,
    FormCheckbox,
    UtilCurrency
  },
  props: {
    value: Object,
    api: Object
  },
  data() {
    return {
      currencyMask,
      quantityMask,
      percentageMask,
      faPoundSign,
      faPercent,
      faSearch,
      faPlusCircle
    }
  }
}
</script>
