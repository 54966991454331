<template>
  <div>
    <UtilLoader :isLoading="matchApi.loading">
      <div class="overflow-y-auto" style="max-height: 245px">
        <div
          v-for="(match, index) in compMatches"
          :key="index"
          class="flex justify-between py-1 w-full hover:bg-yellow-100 even:bg-gray-100"
        >
          <div>
            <a :href="`${baseUrls.adminPortal}/user.php?ref=${match.userRef}`" target="_blank" class="underline">
              {{ match.name }}
            </a>

            <span>{{ match.contact }}</span>
          </div>

          <span>{{ match.region }}</span>

          <FormSelect
            name="status"
            :value="match.status"
            :items="[
              {
                label: 'New',
                value: null
              },
              {
                label: 'Contacted',
                value: 'contacted'
              },
              {
                label: 'Matched',
                value: 'matched'
              }
            ]"
            :emptyFirstOption="false"
            small
            class="ml-4 mb-0"
          />

          <UtilButton color="secondary" small class="ml-2 mr-2 self-start">Assign</UtilButton>
        </div>
      </div>
    </UtilLoader>
  </div>
</template>

<script>
import Api from '@/lib/api'
import UtilLoader from '@/components/utils/UtilLoader.vue'
import FormSelect from '@/components/forms/elements/FormSelect.vue'
import UtilButton from '@/components/utils/UtilButton.vue'

export default {
  components: { UtilLoader, FormSelect, UtilButton },
  name: 'UtilListingMatchmaker',
  props: {
    value: String
  },
  data() {
    return {
      matchApi: new Api()
    }
  },
  computed: {
    compMatches() {
      return [
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        },
        {
          name: 'Chris Jefferson',
          contact: '07742357242',
          userRef: 'C0812E89-5C9F-49E5-A232-D23F84F21B19',
          region: 'Hampshire',
          status: null
        }
      ]
    }
  },
  methods: {
    selectMember() {
      this.$emit('chosenMember', {
        id: 12345,
        name: 'Chris Jefferson',
        contact: '07742357242',
        email: 'chrisjefferson@gmail.com'
      })
    }
  }
}
</script>
