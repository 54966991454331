<template>
  <div>
    <div class="flex items-center mt-4">
      <div class="w-5/12 font-medium">Category</div>

      <div class="flex-grow pl-4">Sheep</div>
    </div>

    <div class="flex items-center mt-4">
      <div class="w-5/12 font-medium">Type</div>

      <div class="flex-grow pl-4">Breeding</div>
    </div>

    <div class="flex items-center mt-4">
      <div class="w-5/12 font-medium">Quantity</div>

      <div class="flex-grow pl-4">5</div>
    </div>

    <div class="flex items-center mt-4">
      <div class="w-5/12 font-medium">Sex</div>

      <div class="flex-grow pl-4">Female</div>
    </div>

    <div class="flex items-center mt-4">
      <div class="w-5/12 font-medium">Average Weight Per Head</div>

      <div class="flex-grow pl-4">100kg</div>
    </div>

    <div class="flex items-center mt-4">
      <div class="w-5/12 font-medium">Price Per Head</div>

      <div class="flex-grow pl-4"><UtilCurrency :value="450" class="font-mono font-bold leading-snug" /></div>
    </div>
  </div>
</template>

<script>
import UtilCurrency from '@/components/utils/UtilCurrency.vue'

export default {
  components: {
    UtilCurrency
  },
  name: 'UtilListingDetails'
}
</script>

<style lang="scss" scoped></style>
