export default {
  maskPattern: 'num',
  blocks: {
    num: {
      mask: Number,
      min: 1,
      max: 10000
    }
  }
}
