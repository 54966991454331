<template>
  <Form @submit="$emit('save')" ref="form">
    <FormTextInput
      name="name"
      label="Name"
      v-model="compValue.name"
      rules="required"
      autocapitalize="none"
      autocorrect="off"
      spellcheck="false"
    />

    <FormTextInput
      name="contactNumber"
      label="Contact Number"
      type="tel"
      :mask="telephoneMask"
      v-model="compValue.contact"
      autocapitalize="none"
      autocorrect="off"
      spellcheck="false"
    />

    <FormTextInput
      name="email"
      label="Email"
      v-model="compValue.email"
      rules="email"
      autocapitalize="none"
      autocorrect="off"
      spellcheck="false"
    />

    <UtilButton type="submit" color="primary" :isLoading="isLoading" block>
      {{ submitButtonLabel }}
    </UtilButton>
  </Form>
</template>

<script>
import telephoneMask from '@/lib/masks/telephone'
import UtilButton from '@/components/utils/UtilButton'
import Form from '@/components/forms/elements/Form'
import FormTextInput from '@/components/forms/elements/FormTextInput.vue'

export default {
  name: 'FormCustomer',
  components: {
    UtilButton,
    Form,
    FormTextInput
  },
  props: {
    value: Object,
    submitButtonLabel: {
      type: String,
      default: 'Save Customer'
    },
    isLoading: Boolean
  },
  data() {
    return {
      telephoneMask
    }
  },
  computed: {
    compValue: {
      get() {
        return this.value
      },
      set(data) {
        this.$emit('input', data)
      }
    }
  }
}
</script>
